.card {
    min-height: 150px;
    min-width: 220px;
}

.space {
    width: 100%;
}

.hourContainer {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 0.5px solid #00000040;
}

.hour {
    font-weight: 400;
    color: black;
    margin-bottom: 0;
    margin-top: 0;
    letter-spacing: 1px;
}

.icon {
    margin-left: 3px;
    color: #f32351;
    width: 15px;
    height: 15px;
}

.icon:hover {
    cursor: pointer;
    filter: brightness(2);
}
