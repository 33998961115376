.spaceSize {
    width: 100% !important;
}

.inputSize {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.buttonSize {
    width: 104px !important;
}

.state {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.state svg {
    width: 17.86px !important;
    height: 17.86px !important;
}

.state svg:hover {
    cursor: pointer;
}
